import { useStyles } from "./Styles.fs.js";
import { createElement } from "react";
import { reactApi } from "../.fable/Feliz.1.35.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Container from "@material-ui/core/Container";
import { toolbarElement } from "./Iconbar.fs.js";
import { tabBarElement } from "./Tabbar.fs.js";
import { keybindingsTable } from "./Keybindings.fs.js";
import { toString } from "../.fable/fable-library.3.1.5/Types.js";
import * as LocalDB from "../../src/helper/LocalDB";
import Button from "@material-ui/core/Button";

export function RootDivComponent(rootDivComponentInputProps) {
    const dispatch = rootDivComponentInputProps.dispatch;
    const model = rootDivComponentInputProps.model;
    const classes = useStyles();
    return createElement("div", {
        className: classes.RootDiv,
        children: reactApi.Children.toArray([MuiHelpers_createElement(Container, [["id", "main-container"], ["component", "main"], ["disableGutters", true], ["children", reactApi.Children.toArray([createElement("div", {
            children: reactApi.Children.toArray([toolbarElement(model, dispatch, classes), tabBarElement(model, dispatch, classes), keybindingsTable(model, dispatch), createElement("br", {}), toString(LocalDB.saveAsFileSupported()), MuiHelpers_createElement(Button, [["variant", "outlined"], ["style", {
                visibility: "collapse",
            }]])]),
        })])]])]),
    });
}

