import { StyleCreator$1__create_4EAC9E1E, StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { singleton, ofArray } from "../.fable/fable-library.3.1.5/List.js";
import { rgb } from "../.fable/Feliz.1.35.0/Colors.fs.js";
import { CssClasses } from "../Model.fs.js";

export const useStyles = StyleImports_makeStyles_get((arg) => {
    let o;
    const styles = StyleCreator$1_$ctor();
    o = (new CssClasses(StyleCreator$1__create_4EAC9E1E(styles, ofArray([["backgroundColor", arg.palette.background.paper], ["marginTop", 4], ["fontSize", 16 + "px"], ["color", "#ccc"], ["height", 100 + "%"], ["fontFamily", "system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif"], ["\u0026 kbd", StyleCreator$1__create_4EAC9E1E(styles, ofArray([["backgroundColor", "hsla(0,0%,50%,.47)"], ["padding", ((((((1 + "px ") + 2) + "px ") + 1) + "px ") + 2) + "px"], ["marginRight", 2], ["marginLeft", 2], ["verticalAlign", "middle"], ["border", (((1 + "px ") + "solid") + " ") + "transparent"], ["borderRadius", 3]]))]])), StyleCreator$1__create_4EAC9E1E(styles, ofArray([["borderRadius", "0 !important"], ["padding", "6px 0 6px 16px !important"]])), StyleCreator$1__create_4EAC9E1E(styles, singleton(["borderLeft", "1px solid rgb(35, 35, 35) !important"])), StyleCreator$1__create_4EAC9E1E(styles, singleton(["\u0026:hover div", StyleCreator$1__create_4EAC9E1E(styles, singleton(["color", rgb(133, 133, 133) + " !important"]))])), StyleCreator$1__create_4EAC9E1E(styles, singleton(["color", "transparent !important"])), StyleCreator$1__create_4EAC9E1E(styles, singleton(["min-width", "0 !important"]))));
    return Object.assign({}, o);
});

