import { some } from "../.fable/fable-library.3.1.5/Option.js";
import { printf, toText } from "../.fable/fable-library.3.1.5/String.js";

export const chunkSize = (1024 * 1024) * 2;

export function readAsText(offset, file) {
    return new Promise(((resolve, reject) => {
        const fr = new FileReader();
        fr.addEventListener("load", (_arg1) => {
            resolve(fr.result);
        });
        fr.addEventListener("error", (err) => {
            console.error(some(err));
            reject((() => {
                throw (new Error(toText(printf("%A"))(err)));
            })());
        });
        fr.readAsText(file.slice(offset, chunkSize));
    }));
}

