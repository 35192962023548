import { useTheme, createMuiTheme, responsiveFontSizes, makeStyles } from "@material-ui/core/styles";
import { class_type } from "../fable-library.3.1.5/Reflection.js";
import { createObj } from "../fable-library.3.1.5/Util.js";

export const StyleImports_makeStyles_get = makeStyles;

export const StyleImports_makeStyles_getWithOpts = makeStyles;

export const StyleImports_makeStyles_obj = makeStyles;

export const StyleImports_makeStyles_objWithOpts = makeStyles;

export const StyleImports_responsiveFontSizes = responsiveFontSizes;

export const StyleImports_responsiveFontSizes_opts = responsiveFontSizes;

export const StyleImports_createMuiTheme_argsArray = createMuiTheme;

export const StyleImports_createMuiTheme_unit = createMuiTheme;

export const StyleImports_useTheme = useTheme;

export class StyleCreator$1 {
    constructor() {
    }
}

export function StyleCreator$1$reflection(gen0) {
    return class_type("Feliz.MaterialUI.StyleCreator`1", [gen0], StyleCreator$1);
}

export function StyleCreator$1_$ctor() {
    return new StyleCreator$1();
}

export function StyleCreator$1__create_4EAC9E1E(_, styles) {
    return createObj(styles);
}

export function StyleCreator$1__create_A7D96BD(_, getStyles) {
    return (arg_1) => createObj(getStyles(arg_1));
}

