import { Union } from "./.fable/fable-library.3.1.5/Types.js";
import { ThemeKind$reflection, ControlId$reflection, TabItemModel$reflection, Height$reflection } from "./Model.fs.js";
import { union_type, array_type, list_type, class_type, string_type, int32_type, float64_type } from "./.fable/fable-library.3.1.5/Reflection.js";
import { SelfMessage$1$reflection } from "./.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EditorCreated", "ToggleWrapText", "WindowWidthChaned", "DebouncerSelfMsg", "EndOfWindowWidthChaned", "TabChanged", "AddEmptyTab", "AddTab", "RemoveTab", "EditorLanguageChanged", "ShowTooltipChanged", "OnDragenter", "OnDragleave", "OnDrop", "OnPromiseError", "ThemeKind", "IncreaseFontSize", "DecreaseFontSize", "ShowKeyBindingsForChanged", "ModelContentChange", "MonacoEditorModulePromiseResolved", "EditorDomElementCreated", "CreateEditor"];
    }
}

export function Msg$reflection() {
    return union_type("Msg.Msg", [], Msg, () => [[["Item", Height$reflection()]], [], [["Item", float64_type]], [["Item", SelfMessage$1$reflection(Msg$reflection())]], [], [["Item", int32_type]], [], [["Item", TabItemModel$reflection()]], [["Item", int32_type]], [["Item", string_type]], [["Item", ControlId$reflection()]], [], [], [["Item", list_type(class_type("Browser.Types.File"))]], [["Item", class_type("System.Exception")]], [["Item", ThemeKind$reflection()]], [], [], [["Item", string_type]], [["Item", array_type(class_type("MonacoEditorTypes.IModelContentChange"))]], [], [["Item", string_type]], []]);
}

