import { getEnumerator } from "../.fable/fable-library.3.1.5/Seq.js";

export const throttle = (() => {
    const timeoutIds = [];
    return (timeout) => ((callback) => {
        void (timeoutIds.push(window.setTimeout((_arg1) => {
            let enumerator = getEnumerator(timeoutIds);
            try {
                while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                    window.clearInterval(enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]());
                }
            }
            finally {
                enumerator.Dispose();
            }
            callback();
        }, timeout)));
    });
})();

export function setTimeout(handler, timeout) {
    void window.setTimeout(handler, timeout);
}

