import { Record, Union } from "../../.fable/fable-library.3.1.5/Types.js";
import { record_type, list_type, class_type, union_type } from "../../.fable/fable-library.3.1.5/Reflection.js";
import { empty } from "../../.fable/fable-library.3.1.5/List.js";
import { printf, toText } from "../../.fable/fable-library.3.1.5/String.js";

export class Key extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "LeftArrow", "RightArrow", "UpArrow", "DownArrow", "Shift", "Ctrl", "Alt", "CtrlMac", "OptMac", "CmdMac", "Home", "End"];
    }
}

export function Key$reflection() {
    return union_type("HtmlEx.Key", [], Key, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Kbd {
    constructor() {
    }
}

export function Kbd$reflection() {
    return class_type("HtmlEx.Kbd", void 0, Kbd);
}

export class HtmlEx {
    constructor() {
    }
}

export function HtmlEx$reflection() {
    return class_type("HtmlEx.HtmlEx", void 0, HtmlEx);
}

export class StyleEx {
    constructor() {
    }
}

export function StyleEx$reflection() {
    return class_type("HtmlEx.StyleEx", void 0, StyleEx);
}

export class WrappedElem extends Record {
    constructor(ValuesReversed) {
        super();
        this.ValuesReversed = ValuesReversed;
    }
}

export function WrappedElem$reflection() {
    return record_type("HtmlEx.WrappedElem", [], WrappedElem, () => [["ValuesReversed", list_type(class_type("Fable.React.ReactElement"))]]);
}

export function WrappedElem_empty() {
    return new WrappedElem(empty());
}

export function wrapper() {
    return WrappedElem_empty();
}

export function getElementById(id) {
    const el = document.getElementById(id);
    if (el == null) {
        return void 0;
    }
    else {
        return el;
    }
}

export function getElementValueById(id) {
    const el = document.getElementById(id);
    if (el == null) {
        throw (new Error(toText(printf("Element %s is null"))(id)));
    }
    else {
        return el;
    }
}

export function click(el) {
    el.click();
}

