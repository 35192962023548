import { StyleImports_createMuiTheme_argsArray, StyleImports_createMuiTheme_unit } from "../.fable/Feliz.MaterialUI.1.2.6/Styles.fs.js";
import { fromFlatEntries } from "../.fable/Feliz.MaterialUI.1.2.6/Flatten.fs.js";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import { rgb } from "../.fable/Feliz.1.35.0/Colors.fs.js";
import { ofArray } from "../.fable/fable-library.3.1.5/List.js";

export const defaultTheme = StyleImports_createMuiTheme_unit();

export const darkTheme = (() => {
    const merge = [];
    return StyleImports_createMuiTheme_argsArray(fromFlatEntries(ofArray([["palette.type", "dark"], ["palette.primary", blue], ["palette.secondary", teal], ["palette.background.default", defaultTheme.palette.grey["900"]], ["palette.background.paper", "#1e1e1e"], ["typography.h1.fontSize", "3rem"], ["typography.h2.fontSize", "2rem"], ["typography.h3.fontSize", "1.5rem"], ["overrides.MuiAppBar.colorDefault", {
        backgroundColor: defaultTheme.palette.grey.A400,
    }], ["overrides.MuiPopover.root", {
        color: "#ccc",
    }], ["overrides.MuiPaper.root", {
        color: "inherit",
        backgroundColor: defaultTheme.palette.grey.A400,
    }], ["overrides.MuiDrawer.paper", {
        backgroundColor: defaultTheme.palette.grey["900"],
    }], ["props.MuiAppBar", {
        color: "default",
    }], ["overrides.MuiButtonBase.root", {
        color: "inherit",
    }], ["overrides.MuiButton.root", {
        color: "inherit",
        textTransform: "none",
        fontSize: "unset",
    }], ["overrides.MuiInputBase.root", {
        color: rgb(133, 133, 133),
    }], ["overrides.MuiInputBase.input", {
        borderRadius: 4,
        position: "relative",
        color: rgb(133, 133, 133),
        borderStyle: "none",
        fontSize: 16 + "px",
        padding: ((((((8 + "px ") + 24) + "px ") + 8) + "px ") + 10) + "px",
    }], ["overrides.MuiInputBase.inputMarginDense", {
        paddingTop: "auto",
    }], ["overrides.MuiIconButton.root", {
        color: rgb(133, 133, 133),
    }], ["overrides.MuiTabs.root", {}], ["overrides.MuiTab.root", {
        maxWidth: "auto",
        textTransform: "none",
        padding: ((((((0 + "px ") + 0) + "px ") + 0) + "px ") + 5) + "px",
    }], ["overrides.MuiSelect.root", {
        textTransform: "none",
    }], ["overrides.MuiSelect.icon", {
        color: rgb(133, 133, 133),
    }], ["overrides.MuiTab.wrapper", {
        flexDirection: "row",
        paddingRight: 2,
    }], ["overrides.MuiFormControl.root", {
        verticalAlign: "inherit",
    }]])), ...merge);
})();

