import { Msg } from "../Msg.fs.js";
import { ofSeq } from "../.fable/fable-library.3.1.5/List.js";
import { iterate, rangeNumber, map, delay } from "../.fable/fable-library.3.1.5/Seq.js";
import { useReact_useEffect_Z101E1A95, useReact_useEffectOnce_3A5B6456 } from "../.fable/Feliz.1.35.0/React.fs.js";
import { some } from "../.fable/fable-library.3.1.5/Option.js";
import { setTimeout } from "../helper/EventHelper.fs.js";
import { getElementValueById } from "../helper/view/HtmlEx.fs.js";
import { DragModel__get_isDragging } from "../Model.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { darkTheme } from "./Themes.fs.js";
import { reactApi } from "../.fable/Feliz.1.35.0/Interop.fs.js";
import { createElement } from "react";
import { RootDivComponent } from "./RootDiv.fs.js";

export function addDragAndDropListener(dispatch) {
    document.addEventListener("dragover", (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    });
    document.addEventListener("dragenter", (e_1) => {
        e_1.stopPropagation();
        e_1.preventDefault();
        dispatch(new Msg(11));
    });
    document.addEventListener("dragleave", (e_2) => {
        e_2.stopPropagation();
        e_2.preventDefault();
        dispatch(new Msg(12));
    });
    document.addEventListener("drop", (e_3) => {
        e_3.stopPropagation();
        e_3.preventDefault();
        const fileList = e_3.dataTransfer.files;
        dispatch(new Msg(13, ofSeq(delay(() => map((i) => fileList.item(i), rangeNumber(0, 1, fileList.length - 1))))));
    });
}

export function App(appInputProps) {
    const dispatch = appInputProps.dispatch;
    const model = appInputProps.model;
    useReact_useEffectOnce_3A5B6456(() => {
        let pr_1, pr;
        window.performance.mark("AppStart");
        window.addEventListener("resize", (_arg1) => {
            dispatch(new Msg(2, window.innerWidth));
        });
        addDragAndDropListener(dispatch);
        void (pr_1 = (pr = import("../../src/editor/MonacoEditor.ts"), pr.then(((_arg2) => {
            dispatch(new Msg(20));
        }))), pr_1.then(void 0, ((ex) => {
            console.log(some(ex));
        })));
        window.addEventListener("load", (_arg4) => {
            console.log(some("domContentLoaded"), window.performance.timing.domContentLoadedEventStart - window.performance.timing.navigationStart);
            console.log(some("domComplete"), window.performance.timing.domComplete - window.performance.timing.navigationStart);
            iterate((entry) => {
                console.log(some(entry.name), entry.startTime);
            }, window.performance.getEntriesByType("mark"));
            setTimeout(() => {
                getElementValueById("underneathEditorElem").style.opacity = "0";
            }, 3000);
        });
    });
    useReact_useEffect_Z101E1A95(() => {
        const editAreaElem = document.querySelector(".monaco-editor-background");
        const marginElem = document.querySelector(".monaco-editor .margin");
        if ((editAreaElem == null) ? true : (marginElem == null)) {
        }
        else if (DragModel__get_isDragging(model.DragModel)) {
            editAreaElem.style.backgroundColor = "#737373";
            marginElem.style.backgroundColor = "#737373";
        }
        else {
            editAreaElem.style.backgroundColor = "";
            marginElem.style.backgroundColor = "";
        }
    }, [DragModel__get_isDragging(model.DragModel)]);
    useReact_useEffect_Z101E1A95(() => {
        const newColor = (model.ThemeKind.tag === 1) ? "" : "#1e1e1e";
        document.body.style.backgroundColor = newColor;
    }, [model.ThemeKind]);
    return MuiHelpers_createElement(ThemeProvider, [["theme", darkTheme], ["children", reactApi.Children.toArray([createElement(RootDivComponent, {
        model: model,
        dispatch: dispatch,
    })])]]);
}

export function render(state, dispatch) {
    return createElement(App, {
        model: state,
        dispatch: dispatch,
    });
}

