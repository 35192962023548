import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import TabContext from "@material-ui/lab/TabContext";
import { int32ToString } from "../.fable/fable-library.3.1.5/Util.js";
import { reactApi } from "../.fable/Feliz.1.35.0/Interop.fs.js";
import Paper from "@material-ui/core/Paper";
import { createElement } from "react";
import * as react from "react";
import Tabs from "@material-ui/core/Tabs";
import { Msg } from "../Msg.fs.js";
import { singleton, mapIndexed } from "../.fable/fable-library.3.1.5/List.js";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import { join } from "../.fable/fable-library.3.1.5/String.js";
import Close from "@material-ui/icons/Close";
import SvgIcon from "@material-ui/core/SvgIcon";
import Add from "@material-ui/icons/Add";
import { EditorComponent } from "./Editor.fs.js";

export function tabBarElement(model, dispatch, classes) {
    let children_2;
    return MuiHelpers_createElement(TabContext, [["value", int32ToString(model.SelectedTabId)], ["children", reactApi.Children.toArray([MuiHelpers_createElement(Paper, [["style", {
        flexGrow: 1,
        marginTop: 5,
    }], ["children", reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
        },
        children: reactApi.Children.toArray([MuiHelpers_createElement(Tabs, [["variant", "scrollable"], ["value", model.SelectedTabId], ["onChange", (_arg38, v) => {
            dispatch(new Msg(5, v));
        }], ["indicatorColor", "primary"], ["textColor", "primary"], ["classes.scrollButtons", "MuiButton-root " + classes.TabsScrollButton], ["children", reactApi.Children.toArray(Array.from(mapIndexed((tabIndex, t) => MuiHelpers_createElement(Tab, [["classes.root", ((("MuiButton-root " + classes.ShowCloseBtnOnHover) + " ") + classes.TabButton) + ((tabIndex === 0) ? "" : (" " + classes.BorderLeft))], ["disableRipple", true], ["label", [createElement("span", {
            key: "1",
            style: {
                flexGrow: 1,
            },
            children: reactApi.Children.toArray([t.Name]),
        }), MuiHelpers_createElement(IconButton, [["key", "2"], ["component", "div"], ["className", join(" ", [classes.CloseBtn])], ["style", {
            height: 20,
            width: 20,
            padding: 0,
            marginLeft: 10,
            marginRight: 4,
        }], ["onClick", (e) => {
            dispatch(new Msg(8, tabIndex));
            e.stopPropagation();
        }], ["children", react.createElement(Close, {
            style: {
                height: 16,
                width: 18,
            },
        })]])]]]), model.TabItems)))]]), MuiHelpers_createElement(SvgIcon, [["stroke", "#FFFFFF"], ["strokeWidth", 1], ["viewBox", "0 0 3 50"], ["style", {
            height: 38,
            margin: "auto",
            marginLeft: 0,
            marginRight: 0,
        }], ["children", reactApi.Children.toArray([createElement("line", {
            x1: "50%",
            y1: "15%",
            x2: "50%",
            y2: "85%",
        })])]]), MuiHelpers_createElement(IconButton, [["style", {
            height: 38,
            width: 38,
            margin: "auto",
            marginLeft: 1,
            marginRight: 5,
        }], ["onClick", (_arg1) => {
            dispatch(new Msg(6));
        }], ["children", react.createElement(Add, {})]])]),
    })])]]), (children_2 = singleton(createElement(EditorComponent, {
        dispatch: dispatch,
    })), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))])]]);
}

