import { createElement } from "react";
import * as react from "react";
import { reactApi } from "../.fable/Feliz.1.35.0/Interop.fs.js";
import { equals } from "../.fable/fable-library.3.1.5/Util.js";
import { EditorLanguage_get_all, EditorLanguage__get_displayText, ControlId } from "../Model.fs.js";
import { MuiHelpers_createElement } from "../.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Msg } from "../Msg.fs.js";
import WrapText from "@material-ui/icons/WrapText";
import { keyValueList } from "../.fable/fable-library.3.1.5/MapUtil.js";
import { ofSeq } from "../.fable/fable-library.3.1.5/List.js";
import { map, empty, singleton, delay } from "../.fable/fable-library.3.1.5/Seq.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.0.1/Fable.React.Extensions.fs.js";
import { setTimeout } from "../helper/EventHelper.fs.js";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { fontSizeDecreaseIcon, fontSizeIncreaseIcon } from "./Icons.fs.js";

export function toolbarElement(model, dispatch, classes) {
    let showTooltip, showTooltip_1, showTooltip_2, showTooltip_3;
    return createElement("div", {
        children: reactApi.Children.toArray([(showTooltip = equals(model.ShowTooltipControlId, new ControlId(1)), MuiHelpers_createElement(Tooltip, [["title", "Wrap text"], ["arrow", true], ["placement", "bottom"], ["children", MuiHelpers_createElement(IconButton, [["style", {
            verticalAlign: "bottom",
            height: 38,
            width: 38,
            marginRight: 5,
            borderRadius: 5,
        }], ["onClick", (_arg1) => {
            dispatch(new Msg(1));
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onMouseEnter", (_arg2) => {
            dispatch(new Msg(10, new ControlId(1)));
        }], ["onTouchStart", (_arg3) => {
            dispatch(new Msg(10, new ControlId(1)));
        }], ["onMouseLeave", (_arg4) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onTouchEnd", (_arg5) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["children", react.createElement(WrapText, keyValueList(ofSeq(delay(() => {
            if (model.EditorOptions.WrapText) {
                return singleton(["color", "primary"]);
            }
            else {
                return empty();
            }
        })), 1))]])], ["open", showTooltip]])), (showTooltip_1 = equals(model.ShowTooltipControlId, new ControlId(2)), MuiHelpers_createElement(Tooltip, [["title", "Language"], ["arrow", true], ["placement", "bottom"], ["children", MuiHelpers_createElement(FormControl, [["size", "small"], ["variant", "outlined"], ["children", reactApi.Children.toArray([MuiHelpers_createElement(Select, [["classes.root", "MuiButton-root"], ["value", model.EditorLanguage], ["onChange", (e, _arg24) => {
            dispatch(new Msg(9, Browser_Types_Event__Event_get_Value(e)));
        }], ["onOpen", (_arg6) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onClose", (_arg8) => {
            setTimeout((_arg7) => {
                dispatch(new Msg(10, new ControlId(0)));
            }, 20);
        }], ["onMouseEnter", (_arg9) => {
            dispatch(new Msg(10, new ControlId(2)));
        }], ["onTouchStart", (_arg10) => {
            dispatch(new Msg(10, new ControlId(2)));
        }], ["onMouseLeave", (_arg11) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onTouchEnd", (_arg12) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["input", MuiHelpers_createElement(InputBase, [])], ["children", reactApi.Children.toArray(Array.from(ofSeq(delay(() => map((el) => MuiHelpers_createElement(MenuItem, [["value", el], ["children", reactApi.Children.toArray([EditorLanguage__get_displayText(el)])]]), EditorLanguage_get_all())))))]])])]])], ["open", showTooltip_1]])), (showTooltip_2 = equals(model.ShowTooltipControlId, new ControlId(3)), MuiHelpers_createElement(Tooltip, [["title", "Increase Font Size"], ["arrow", true], ["placement", "bottom"], ["children", MuiHelpers_createElement(IconButton, [["style", {
            verticalAlign: "bottom",
            height: 38,
            width: 38,
            marginLeft: 5,
            marginRight: 0,
            padding: 2,
            borderRadius: 5,
        }], ["onClick", (_arg13) => {
            dispatch(new Msg(16));
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onMouseEnter", (_arg14) => {
            dispatch(new Msg(10, new ControlId(3)));
        }], ["onTouchStart", (_arg15) => {
            dispatch(new Msg(10, new ControlId(3)));
        }], ["onMouseLeave", (_arg16) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onTouchEnd", (_arg17) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["children", fontSizeIncreaseIcon()]])], ["open", showTooltip_2]])), (showTooltip_3 = equals(model.ShowTooltipControlId, new ControlId(4)), MuiHelpers_createElement(Tooltip, [["title", "Decrease Font Size"], ["arrow", true], ["placement", "bottom"], ["children", MuiHelpers_createElement(IconButton, [["style", {
            verticalAlign: "bottom",
            height: 38,
            width: 38,
            marginLeft: 0,
            marginRight: 5,
            padding: 2,
            paddingTop: 6,
            borderRadius: 5,
        }], ["onClick", (_arg18) => {
            dispatch(new Msg(17));
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onMouseEnter", (_arg19) => {
            dispatch(new Msg(10, new ControlId(4)));
        }], ["onTouchStart", (_arg20) => {
            dispatch(new Msg(10, new ControlId(4)));
        }], ["onMouseLeave", (_arg21) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["onTouchEnd", (_arg22) => {
            dispatch(new Msg(10, new ControlId(0)));
        }], ["children", fontSizeDecreaseIcon()]])], ["open", showTooltip_3]]))]),
    });
}

