import { React_createDisposable_3A5B6456, useReact_useEffectOnce_Z5ECA432F, useReact_useElementRef } from "../.fable/Feliz.1.35.0/React.fs.js";
import { int32ToString } from "../.fable/fable-library.3.1.5/Util.js";
import { Msg } from "../Msg.fs.js";
import { some } from "../.fable/fable-library.3.1.5/Option.js";
import { createElement } from "react";
import { reactApi } from "../.fable/Feliz.1.35.0/Interop.fs.js";

export function EditorComponent(props) {
    const divEl = useReact_useElementRef();
    useReact_useEffectOnce_Z5ECA432F(() => {
        const matchValue = divEl.current;
        if (matchValue == null) {
        }
        else {
            const x = matchValue;
            x.id = "editorElem";
            const heightTillBottomScreen = (~(~((window.innerHeight - x.getBoundingClientRect().top) - 2))) | 0;
            const height = ((heightTillBottomScreen < 300) ? 300 : heightTillBottomScreen) | 0;
            x.style.height = (int32ToString(height) + "px");
            props.dispatch(new Msg(21, x.id));
        }
        return React_createDisposable_3A5B6456(() => {
            console.error(some("Should never dispose EditorComponent"));
        });
    });
    return createElement("div", {
        style: {
            position: "relative",
        },
        children: reactApi.Children.toArray([createElement("div", {
            id: "underneathEditorElem",
            style: {
                position: "absolute",
                margin: 30,
                fontSize: 32 + "px",
                color: "#CCCCCC66",
                transitionProperty: "opacity",
                transitionTimingFunction: "ease-in",
                transitionDuration: 2 + "s",
            },
            children: reactApi.Children.toArray(["Write here or drop files."]),
        }), createElement("div", {
            style: {
                display: "block",
                width: "auto",
                height: "auto",
                minHeight: 100,
                border: (((1 + "px ") + "solid") + " ") + "#858585",
                zIndex: 6,
            },
            ref: divEl,
        })]),
    });
}

export default ((props) => createElement(EditorComponent, props));

