import { Record } from "../.fable/fable-library.3.1.5/Types.js";
import { record_type, int32_type } from "../.fable/fable-library.3.1.5/Reflection.js";

export class Dimension extends Record {
    constructor(width, height) {
        super();
        this.width = (width | 0);
        this.height = (height | 0);
    }
}

export function Dimension$reflection() {
    return record_type("MonacoEditorTypes.Dimension", [], Dimension, () => [["width", int32_type], ["height", int32_type]]);
}

