import { navigatorObj } from "./ts2fs/DomEx.fs.js";
import { Height, TabItemModel, monacoEditor, DragModel, EditorOptions, Model, ThemeKind, DragModel_get_initial, ControlId, EditorOptions_get_initial, TabItemModel_get_initial } from "./Model.fs.js";
import { length, map as map_1, max, item, empty, ofSeq, cons, getSlice, append, singleton } from "./.fable/fable-library.3.1.5/List.js";
import { update as update_1, bounce, create } from "./.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { Cmd_ofSub, Cmd_OfFunc_result, Cmd_map, Cmd_OfPromise_either, Cmd_batch, Cmd_none } from "./.fable/Fable.Elmish.3.0.6/cmd.fs.js";
import { printf, toText, endsWith } from "./.fable/fable-library.3.1.5/String.js";
import { sumBy, map, delay as delay_1, iterate } from "./.fable/fable-library.3.1.5/Seq.js";
import { some, value, toArray } from "./.fable/fable-library.3.1.5/Option.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { readAsText } from "./helper/FileTools.fs.js";
import { Msg } from "./Msg.fs.js";
import { fromSeconds } from "./.fable/fable-library.3.1.5/TimeSpan.js";
import { getElementValueById, getElementById } from "./helper/view/HtmlEx.fs.js";
import { Dimension } from "./editor/MonacoEditorTypes.fs.js";
import { int32ToString, comparePrimitives } from "./.fable/fable-library.3.1.5/Util.js";

export function getOS() {
    if (navigatorObj == null) {
        return "Unknown";
    }
    else {
        const n = navigatorObj;
        const userAgent = n.userAgent;
        if (userAgent.indexOf("Windows") >= 0) {
            return "Windows";
        }
        else if (userAgent.indexOf("Macintosh") >= 0) {
            return "Mac";
        }
        else if (((((userAgent.indexOf("Macintosh") >= 0) ? true : (userAgent.indexOf("iPad") >= 0)) ? true : (userAgent.indexOf("iPhone") >= 0)) ? (!!n.maxTouchPoints) : false) ? (n.maxTouchPoints > 0) : false) {
            return "Mac";
        }
        else if (userAgent.indexOf("Linux") >= 0) {
            return "Linux";
        }
        else {
            return "Unknown";
        }
    }
}

export const os = getOS();

export function init() {
    return [new Model(0, singleton(TabItemModel_get_initial()), 0, EditorOptions_get_initial(), "plaintext", void 0, false, new ControlId(0), window.innerWidth, window.devicePixelRatio, create(), DragModel_get_initial(), new ThemeKind(0), os, os), Cmd_none()];
}

export function getLanguageFromFilename(fileName) {
    if (endsWith(fileName, ".md")) {
        return "markdown";
    }
    else if (endsWith(fileName, ".js")) {
        return "javascript";
    }
    else if (endsWith(fileName, ".ts")) {
        return "typescript";
    }
    else if (endsWith(fileName, ".json")) {
        return "json";
    }
    else if (endsWith(fileName, ".xml")) {
        return "xml";
    }
    else if (endsWith(fileName, ".yaml")) {
        return "yaml";
    }
    else if (endsWith(fileName, ".sql")) {
        return "sql";
    }
    else {
        return "plaintext";
    }
}

export function addItemAtIndex(list, index, newItem) {
    return append(getSlice(void 0, index - 1, list), cons(newItem, getSlice(index, void 0, list)));
}

export function removeItemAtIndex(list, index) {
    return append(getSlice(void 0, index - 1, list), getSlice(index + 1, void 0, list));
}

export function replaceItemAtIndex(list, index, newItem) {
    return append(getSlice(void 0, index - 1, list), cons(newItem, getSlice(index + 1, void 0, list)));
}

export function updateEditorOptions(msg, model) {
    if (msg.tag === 1) {
        return [new EditorOptions(!model.WrapText), Cmd_none()];
    }
    else {
        throw (new Error(toText(printf("No case implemented to update EditorOptions for message %A"))(msg)));
    }
}

export function updateDragModel(msg, model) {
    switch (msg.tag) {
        case 13: {
            return [DragModel_get_initial(), Cmd_none()];
        }
        case 11: {
            return [new DragModel(model.DragenterCount + 1, model.DragleaveCount), Cmd_none()];
        }
        case 12: {
            return [new DragModel(model.DragenterCount, model.DragleaveCount + 1), Cmd_none()];
        }
        default: {
            throw (new Error(toText(printf("No case implemented to update updateDragModel for message %A"))(msg)));
        }
    }
}

export function update(msg, model) {
    let modelIndex_1, untitledIndex;
    switch (msg.tag) {
        case 1: {
            iterate((editor) => {
                editor.setWordWrap(!model.EditorOptions.WrapText);
                editor.focus();
            }, toArray(monacoEditor()));
            const patternInput = updateEditorOptions(msg, model.EditorOptions);
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, patternInput[0], model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), patternInput[1]];
        }
        case 13: {
            const patternInput_1 = updateDragModel(msg, model.DragModel);
            const tabItemModelPromises = (monacoEditor() != null) ? ofSeq(delay_1(() => map((file) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (readAsText(0, file).then(((_arg1) => {
                const text = _arg1;
                const syntaxLang = getLanguageFromFilename(file.name);
                const modelIndex = value(monacoEditor()).addTextModel(text, syntaxLang) | 0;
                return Promise.resolve((new TabItemModel(file.name, modelIndex, syntaxLang, 0, text.length)));
            }))))), msg.fields[0]))) : empty();
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, patternInput_1[0], model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_batch(cons(patternInput_1[1], ofSeq(delay_1(() => map((t) => Cmd_OfPromise_either(() => t, void 0, (arg0) => (new Msg(7, arg0)), (arg0_1) => (new Msg(14, arg0_1))), tabItemModelPromises)))))];
        }
        case 2: {
            const patternInput_2 = bounce(fromSeconds(1.5), "unused", new Msg(4), model.Debouncer);
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, msg.fields[0], model.DevicePixelRatio, patternInput_2[0], model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_batch(singleton(Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1])))];
        }
        case 3: {
            const patternInput_3 = update_1(msg.fields[0], model.Debouncer);
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, patternInput_3[0], model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), patternInput_3[1]];
        }
        case 4: {
            const matchValue = getElementById("main-container");
            if (matchValue == null) {
            }
            else {
                const mainContainerWidth = (~(~matchValue.clientWidth)) | 0;
                iterate((editor_1) => {
                    editor_1.layout(new Dimension(mainContainerWidth + -2, model.EditorHeight));
                }, toArray(monacoEditor()));
            }
            return [model, Cmd_none()];
        }
        case 5: {
            const selectedTabId = msg.fields[0] | 0;
            const tabModel = item(selectedTabId, model.TabItems);
            iterate((editor_2) => {
                editor_2.setTextModelIndex(tabModel.ModelIndex);
                editor_2.focus();
            }, toArray(monacoEditor()));
            return [new Model(selectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, tabModel.Language, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_none()];
        }
        case 9: {
            const editorLanguage = msg.fields[0];
            iterate((editor_3) => {
                editor_3.setLanguage(editorLanguage);
            }, toArray(monacoEditor()));
            const tabModel_1 = item(model.SelectedTabId, model.TabItems);
            return [new Model(model.SelectedTabId, replaceItemAtIndex(model.TabItems, model.SelectedTabId, new TabItemModel(tabModel_1.Name, tabModel_1.ModelIndex, editorLanguage, tabModel_1.UntitledIndex, tabModel_1.ContentSize)), model.EditorHeight, model.EditorOptions, editorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_none()];
        }
        case 10: {
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, msg.fields[0], model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_none()];
        }
        case 11:
        case 12: {
            const patternInput_4 = updateDragModel(msg, model.DragModel);
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, patternInput_4[0], model.ThemeKind, model.OS, model.ShowKeyBindingsFor), patternInput_4[1]];
        }
        case 6: {
            return [model, (monacoEditor() != null) ? (modelIndex_1 = (value(monacoEditor()).addTextModel("", "plaintext") | 0), (untitledIndex = (((model.TabItems.tail == null) ? 1 : (1 + max(map_1((x) => x.UntitledIndex, model.TabItems), {
                Compare: (x_1, y) => comparePrimitives(x_1, y),
            }))) | 0), Cmd_OfFunc_result(new Msg(7, new TabItemModel("Untitled " + int32ToString(untitledIndex), modelIndex_1, "plaintext", untitledIndex, 0))))) : Cmd_none()];
        }
        case 7: {
            const tabs = append(model.TabItems, singleton(msg.fields[0]));
            return [new Model(model.SelectedTabId, tabs, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_OfFunc_result(new Msg(5, length(tabs) - 1))];
        }
        case 8: {
            const index = msg.fields[0] | 0;
            const tabs_1 = removeItemAtIndex(model.TabItems, index);
            const selectedTabId_1 = (((model.SelectedTabId > index) ? true : (model.SelectedTabId === length(tabs_1))) ? (model.SelectedTabId - 1) : model.SelectedTabId) | 0;
            return [new Model(model.SelectedTabId, tabs_1, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), (tabs_1.tail == null) ? Cmd_OfFunc_result(new Msg(6)) : Cmd_OfFunc_result(new Msg(5, selectedTabId_1))];
        }
        case 14: {
            console.error(some("An promise was rejected: "), msg.fields[0]);
            return [model, Cmd_none()];
        }
        case 15: {
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, msg.fields[0], model.OS, model.ShowKeyBindingsFor), Cmd_none()];
        }
        case 16: {
            iterate((editor_4) => {
                editor_4.increaseFontSize();
                editor_4.focus();
            }, toArray(monacoEditor()));
            return [model, Cmd_none()];
        }
        case 17: {
            iterate((editor_5) => {
                editor_5.decreaseFontSize();
                editor_5.focus();
            }, toArray(monacoEditor()));
            return [model, Cmd_none()];
        }
        case 18: {
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, msg.fields[0]), Cmd_none()];
        }
        case 19: {
            const sizeChange = sumBy((c) => (c.text.length - c.rangeLength), msg.fields[0], {
                GetZero: () => 0,
                Add: (x_2, y_1) => (x_2 + y_1),
            }) | 0;
            const tabModel_2 = item(model.SelectedTabId, model.TabItems);
            const newTabModel_1 = new TabItemModel(tabModel_2.Name, tabModel_2.ModelIndex, tabModel_2.Language, tabModel_2.UntitledIndex, tabModel_2.ContentSize + sizeChange);
            const newTabModels_1 = replaceItemAtIndex(model.TabItems, model.SelectedTabId, newTabModel_1);
            console.log(some("New size: "), newTabModel_1.ContentSize);
            return [new Model(model.SelectedTabId, newTabModels_1, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), Cmd_none()];
        }
        case 20: {
            window.performance.mark("MonacoEditorModulePromiseResolved");
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, true, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), (model.EditorDomElementId != null) ? Cmd_OfFunc_result(new Msg(22)) : Cmd_none()];
        }
        case 21: {
            return [new Model(model.SelectedTabId, model.TabItems, model.EditorHeight, model.EditorOptions, model.EditorLanguage, msg.fields[0], model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), model.IsMonacoEditorModulePromiseResolved ? Cmd_OfFunc_result(new Msg(22)) : Cmd_none()];
        }
        case 22: {
            const editorElem = getElementValueById(value(model.EditorDomElementId));
            const width = (~(~editorElem.clientWidth)) | 0;
            const height_1 = (~(~editorElem.clientHeight)) | 0;
            let editorCreatedPromise;
            const pr = import("../src/editor/MonacoEditor.ts");
            editorCreatedPromise = (pr.then(((p) => {
                monacoEditor(p.create(editorElem, new Dimension(width, height_1)), true);
                return new Height(0, height_1);
            })));
            return [model, Cmd_OfPromise_either(() => editorCreatedPromise, void 0, (arg0_3) => (new Msg(0, arg0_3)), (arg0_4) => (new Msg(14, arg0_4)))];
        }
        default: {
            return [new Model(model.SelectedTabId, model.TabItems, msg.fields[0].fields[0], model.EditorOptions, model.EditorLanguage, model.EditorDomElementId, model.IsMonacoEditorModulePromiseResolved, model.ShowTooltipControlId, model.WindowInnerWidth, model.DevicePixelRatio, model.Debouncer, model.DragModel, model.ThemeKind, model.OS, model.ShowKeyBindingsFor), (monacoEditor() != null) ? (value(monacoEditor()).focus(), Cmd_ofSub((dispatch) => {
                value(monacoEditor()).onDidChangeContent(0, (e) => {
                    dispatch(new Msg(19, e.changes));
                });
            })) : Cmd_none()];
        }
    }
}

