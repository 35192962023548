import { ofArray } from "./.fable/fable-library.3.1.5/List.js";
import { Record, Union } from "./.fable/fable-library.3.1.5/Types.js";
import { float64_type, option_type, list_type, class_type, string_type, record_type, bool_type, union_type, int32_type } from "./.fable/fable-library.3.1.5/Reflection.js";
import { State$reflection } from "./.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { createAtom } from "./.fable/fable-library.3.1.5/Util.js";

export function EditorLanguage__get_displayText(x) {
    switch (x) {
        case "markdown": {
            return "Markdown";
        }
        case "javascript": {
            return "JavaScript";
        }
        case "typescript": {
            return "TypeScript";
        }
        case "json": {
            return "JSON";
        }
        case "xml": {
            return "XML";
        }
        case "yaml": {
            return "YAML";
        }
        case "sql": {
            return "SQL";
        }
        default: {
            return "Plain text";
        }
    }
}

export function EditorLanguage_get_all() {
    return ofArray(["plaintext", "markdown", "javascript", "typescript", "json", "xml", "yaml", "sql"]);
}

export class Height extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Height"];
    }
}

export function Height$reflection() {
    return union_type("Model.Height", [], Height, () => [[["Item", int32_type]]]);
}

export class EditorOptions extends Record {
    constructor(WrapText) {
        super();
        this.WrapText = WrapText;
    }
}

export function EditorOptions$reflection() {
    return record_type("Model.EditorOptions", [], EditorOptions, () => [["WrapText", bool_type]]);
}

export function EditorOptions_get_initial() {
    return new EditorOptions(false);
}

export class DragModel extends Record {
    constructor(DragenterCount, DragleaveCount) {
        super();
        this.DragenterCount = (DragenterCount | 0);
        this.DragleaveCount = (DragleaveCount | 0);
    }
}

export function DragModel$reflection() {
    return record_type("Model.DragModel", [], DragModel, () => [["DragenterCount", int32_type], ["DragleaveCount", int32_type]]);
}

export function DragModel_get_initial() {
    return new DragModel(0, 0);
}

export function DragModel__get_isDragging(x) {
    return x.DragenterCount > x.DragleaveCount;
}

export class TabItemModel extends Record {
    constructor(Name, ModelIndex, Language, UntitledIndex, ContentSize) {
        super();
        this.Name = Name;
        this.ModelIndex = (ModelIndex | 0);
        this.Language = Language;
        this.UntitledIndex = (UntitledIndex | 0);
        this.ContentSize = (ContentSize | 0);
    }
}

export function TabItemModel$reflection() {
    return record_type("Model.TabItemModel", [], TabItemModel, () => [["Name", string_type], ["ModelIndex", int32_type], ["Language", string_type], ["UntitledIndex", int32_type], ["ContentSize", int32_type]]);
}

export function TabItemModel_get_initial() {
    return new TabItemModel("Untitled 1", 0, "plaintext", 1, 0);
}

export class ControlId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["None", "WrapText", "EditorLanguage", "IncreaseFontSize", "DecreaseFontSize"];
    }
}

export function ControlId$reflection() {
    return union_type("Model.ControlId", [], ControlId, () => [[], [], [], [], []]);
}

export class ThemeKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dark", "Light"];
    }
}

export function ThemeKind$reflection() {
    return union_type("Model.ThemeKind", [], ThemeKind, () => [[], []]);
}

export function OS_get_all() {
    return ofArray(["Windows", "Mac", "Linux"]);
}

export class CssClasses extends Record {
    constructor(RootDiv, TabButton, BorderLeft, ShowCloseBtnOnHover, CloseBtn, TabsScrollButton) {
        super();
        this.RootDiv = RootDiv;
        this.TabButton = TabButton;
        this.BorderLeft = BorderLeft;
        this.ShowCloseBtnOnHover = ShowCloseBtnOnHover;
        this.CloseBtn = CloseBtn;
        this.TabsScrollButton = TabsScrollButton;
    }
}

export function CssClasses$reflection() {
    return record_type("Model.CssClasses", [], CssClasses, () => [["RootDiv", string_type], ["TabButton", string_type], ["BorderLeft", string_type], ["ShowCloseBtnOnHover", string_type], ["CloseBtn", string_type], ["TabsScrollButton", string_type]]);
}

export class XIcon extends Record {
    constructor(Element$, Name) {
        super();
        this.Element = Element$;
        this.Name = Name;
    }
}

export function XIcon$reflection() {
    return record_type("Model.XIcon", [], XIcon, () => [["Element", class_type("Fable.React.ReactElement")], ["Name", string_type]]);
}

export class Model extends Record {
    constructor(SelectedTabId, TabItems, EditorHeight, EditorOptions, EditorLanguage, EditorDomElementId, IsMonacoEditorModulePromiseResolved, ShowTooltipControlId, WindowInnerWidth, DevicePixelRatio, Debouncer, DragModel, ThemeKind, OS, ShowKeyBindingsFor) {
        super();
        this.SelectedTabId = (SelectedTabId | 0);
        this.TabItems = TabItems;
        this.EditorHeight = (EditorHeight | 0);
        this.EditorOptions = EditorOptions;
        this.EditorLanguage = EditorLanguage;
        this.EditorDomElementId = EditorDomElementId;
        this.IsMonacoEditorModulePromiseResolved = IsMonacoEditorModulePromiseResolved;
        this.ShowTooltipControlId = ShowTooltipControlId;
        this.WindowInnerWidth = WindowInnerWidth;
        this.DevicePixelRatio = DevicePixelRatio;
        this.Debouncer = Debouncer;
        this.DragModel = DragModel;
        this.ThemeKind = ThemeKind;
        this.OS = OS;
        this.ShowKeyBindingsFor = ShowKeyBindingsFor;
    }
}

export function Model$reflection() {
    return record_type("Model.Model", [], Model, () => [["SelectedTabId", int32_type], ["TabItems", list_type(TabItemModel$reflection())], ["EditorHeight", int32_type], ["EditorOptions", EditorOptions$reflection()], ["EditorLanguage", string_type], ["EditorDomElementId", option_type(string_type)], ["IsMonacoEditorModulePromiseResolved", bool_type], ["ShowTooltipControlId", ControlId$reflection()], ["WindowInnerWidth", float64_type], ["DevicePixelRatio", float64_type], ["Debouncer", State$reflection()], ["DragModel", DragModel$reflection()], ["ThemeKind", ThemeKind$reflection()], ["OS", string_type], ["ShowKeyBindingsFor", string_type]]);
}

export const monacoEditor = createAtom(void 0);

